import DomainUtils from '@utils/domain.utils';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { IAlertOptions, useAlert } from './use-alert';
import { useCustomer } from './use-customer/use-customer';

type Options = {
    callbackRoute?: string;
} & Partial<IAlertOptions>;

export const useLoginAlert = () => {
    const router = useRouter();
    const t = useTranslations();
    const { redirectToLogin } = useCustomer();
    const { showAlert } = useAlert();

    const showLoginAlert = ({ message = t('pages.notLoggedIn'), buttonText = t('login.login'), callbackRoute = router.asPath, onConfirmButtonClick, ...options }: Options = {}) => {
        if (DomainUtils.isLge) {
            window.location.href = 'lgemma://login';
            return;
        }

        showAlert({
            ...options,
            message,
            buttonText,
            variant: 'confirm',
            onConfirmButtonClick: () => {
                onConfirmButtonClick?.();
                redirectToLogin({ callbackRoute });
            },
        });
    };

    return { showLoginAlert };
};
