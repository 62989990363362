import Page from '@models/page';
import { createContext, useContext } from 'react';

type ContextValue = {
    page?: Page;
};

const PageContext = createContext<ContextValue | null>(null);

type ProviderProps = ContextValue & {
    children?: React.ReactNode;
};

export const PageProvider = ({ children, ...props }: ProviderProps) => {
    return <PageContext.Provider value={props}>{children}</PageContext.Provider>;
};

export const usePage = () => {
    const value = useContext(PageContext);

    if (value === null) {
        return { page: undefined };
    }

    return value;
};
