import EnvironmentUtils from '@utils/environment.utils';
import safeWindow from './safe-window.service';
import snakecaseKeys from 'snakecase-keys';

export type KakaoMessageParams = {
    title: string;
    description?: string;
    imageUrl?: string;
    linkUrl: string;
    buttonLabel?: string;
    templateId?: string;
    kakaoJsKey?: string;
    pageId?: string;
    customerId?: string;
};

class KakaoService {
    private static _ensureInitialized(kakaoJsKey?: string): void {
        if (safeWindow?.Kakao && !safeWindow.Kakao?.isInitialized()) {
            safeWindow.Kakao.init(kakaoJsKey || EnvironmentUtils.KAKAO_KEY || '');
        }
    }

    static sendLink({ title, description, imageUrl, linkUrl, buttonLabel, templateId, kakaoJsKey, pageId, customerId }: KakaoMessageParams): void {
        this._ensureInitialized(kakaoJsKey);
        const serverCallbackArgs = snakecaseKeys({ pageId, customerId });

        if (safeWindow?.Kakao) {
            if (!!templateId) {
                safeWindow.Kakao.Share.sendCustom({
                    templateId: Number(templateId),
                    templateArgs: {
                        og_title: title || '',
                        og_description: description || '',
                        og_image: imageUrl || '',
                        og_url: linkUrl || '',
                    },
                    serverCallbackArgs,
                });
                return;
            }

            safeWindow.Kakao.Share.sendDefault({
                objectType: 'feed',
                content: {
                    title: title,
                    imageUrl: imageUrl || '',
                    description: description || '',
                    link: {
                        mobileWebUrl: linkUrl,
                        webUrl: linkUrl,
                    },
                },
                buttons: buttonLabel
                    ? [
                          {
                              title: buttonLabel,
                              link: {
                                  mobileWebUrl: linkUrl,
                                  webUrl: linkUrl,
                              },
                          },
                      ]
                    : [],
                serverCallbackArgs,
            });
        }
    }

    static isAvailable: boolean = !!EnvironmentUtils.KAKAO_KEY;
}

export default KakaoService;
